import React, { useState } from 'react';
import { info } from '../data/data'
import { Main, NavBar} from '../components';
// import useGetData from '../hooks/useGetData';

const Calendar = () => {
    // const data = useGetData();
    
    const [lng, setLng] = useState('en'); 
    const data = lng === 'es' ? info[0].es : info[0].en;

    return data.length === 0 ? <h1>Loading...</h1> : (
        <Main>
            <NavBar lng={lng} setLng={setLng} />
            Calendar
        </Main>
    );
}

export default Calendar;