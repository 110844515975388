import React from 'react';
import H2Styled from '../style/H2Styled';
import H3Styled from '../style/H3Styled';
import PStyled from '../style/PStyled';
import i18n from '../i18n.json';

export default props => {
    const text = props.lng === 'en' ? i18n.en : i18n.es;

    return (
        <div className="Experience">
            <H2Styled>{text.experience}</H2Styled>
            <div className="Experience-container">
                {props.data.map((item, i) => (
                    <div className="Experience-item" key={`Exp-${i}`}>
                        <H3Styled>
                            {item.jobTitle} @ {item.company} 
                            <span>
                                {item.startDate} - {item.endDate}
                            </span>
                        </H3Styled>
                        <ul>
                            {item.jobDescription.length > 0 && item.jobDescription.map((desc, j) => (
                                <li key={`job-${item.company}-${j}`}>
                                    <PStyled className="m-0">{desc}</PStyled>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
}