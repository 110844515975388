import React from 'react';
import styled from 'styled-components';

const MainStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: minmax(auto, 1024px);
    justify-content: center;
    padding: 1em;
    .m-0 {
        margin: 0;
    }
`;

const Main = ({ children }) => {
    return (
        <MainStyled >
            {children}
        </MainStyled>
    );
}

export default Main;
