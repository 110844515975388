import React from 'react';
import H2Styled from '../style/H2Styled';
import H3Styled from '../style/H3Styled';
import PStyled from '../style/PStyled';
import i18n from '../i18n.json';

export default props => {
    const text = props.lng === 'en' ? i18n.en : i18n.es;

    return (
        <div className="Education">
            <H2Styled>{text.education}</H2Styled>
            <div className="Education-container">
                {props.data.map((item, i) => (
                    <div className="Education-item" key={`Edu-${i}`}>
                        <H3Styled>{item.degree} @ {item.institution}
                            <span>{item.startDate} - {item.endDate}</span>
                        </H3Styled>
                        <ul>
                            {item.description.length > 0 && item.description.map((desc, j) => (
                                <li key={`job-${item.company}-${j}`}>
                                    <PStyled className="m-0">{desc}</PStyled>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
}