import React from 'react';

const Info = ({ children }) => (
    <div className="Info" >
        <div className="Info-container" >
            {children}
        </div>
    </div>
);

export default Info;