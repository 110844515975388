import React, { useState } from 'react';
import styled from 'styled-components';
import { info } from '../data/data'
import { Main, Sidebar, Info, About, Education, Experience, Certificates, Skills, NavBar} from '../components';
import photo from './../img/photo.png';
// import useGetData from '../hooks/useGetData';

const MainContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 2rem;
    grid-row-gap: 0.5em;
    
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;

const Resume = () => {
    // const data = useGetData();
    
    const [lng, setLng] = useState('en'); 
    const data = lng === 'es' ? info[0].es : info[0].en;

    return data.length === 0 ? <h1>Loading...</h1> : (
        <Main>
            <NavBar lng={lng} setLng={setLng} />
            <MainContainer>
                <Sidebar>
                    <About 
                        name={data.name}
                        profession={data.profession}
                        bio={data.bio}
                        address={data.address}
                        social={data.social}
                        avatar={photo}
                    />
                </Sidebar>
                <Info>
                    <Education data={data.education} lng={lng} />
                    <Experience data={data.experience} lng={lng} />
                    <Certificates data={data.certificate} lng={lng} />
                    <Skills data={data.skills} lng={lng} />
                </Info>
            </MainContainer>
        </Main>
    );
}

export default Resume;