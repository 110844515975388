import React from 'react';
import styled from 'styled-components';

const H2 = styled.h2`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    letter-spacing: 0.8px;
    margin: 1em 0 0 0;
    color: #663399;
`;

const H2Styled = ({ children }) => (
    <H2>
        {children}
    </H2>
);

export default H2Styled;