import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Resume, Portafolio, Calendar } from "../pages";

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body {
        font-family: 'Lato', sans-serif;
        padding: 0;
        background: #f5f5f5;
    }
`;

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <GlobalStyle />
        <Redirect from="/" to="/resume" />
        <Switch>
          <Route path="/resume" component={Resume} />
          <Route path="/portafolio" component={Portafolio} />
          <Route path="/calendar" component={Calendar} />
        </Switch>
      </BrowserRouter>
    );
  }
}