import React from 'react';
import H2Styled from '../style/H2Styled';
import H3Styled from '../style/H3Styled';
import PStyled from '../style/PStyled';
import i18n from '../i18n.json';

export default props => {
    const text = props.lng === 'en' ? i18n.en : i18n.es;
    
    return (
    <   div className="Certificates">
            <H2Styled>{text.certificates}</H2Styled>
            <div className="Certificates-container">
                {props.data.map((item, i) => (
                    <div className="Certificates-item" key={`Cert-${i}`}>
                        <H3Styled>{item.name} @ {item.institution}
                            <span>{item.date}</span>
                        </H3Styled>
                        <PStyled>{item.description}</PStyled>
                    </div>
                ))}
            </div>
        </div>
    );
}