import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import PStyled from '../style/PStyled';
import i18n from '../i18n.json';

const NavBarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    aling-items: center;
    .Languages {
        display: flex;
        justify-content: center;
        aling-items: center;
        .lngSeparator {
            margin: 0 8px  !important;
        }
        p.lng {
            color: #757575;
            font-weight: 500;
            margin: .5em 0 1.2em 0;
            cursor: pointer;
        }
        p.lngSelected {
            text-shadow: 1px 1px 1px #663399;
            color: #663399;
            cursor: default;
        }
    }

    p, label {
        margin: 0 !important;
    }
`;

const NavBar = props => {
    const text = props.lng === 'en' ? i18n.en : i18n.es;

    return (
        <div className="NavBar">
            <NavBarWrapper>
                <div className="Links">
                    <Link to="/resume">{text.homeLink}</Link>
                    {/* <Link to="/calendar">{text.calendar}</Link> */}
                    {/* <Link to="/portafolio">Portafolio</Link> */}
                </div>
                <div className="Languages">
                    <div onClick={props.setLng.bind(this, 'en')}>
                        <p className={props.lng === 'en' ? 'lng lngSelected' : 'lng'}>EN</p>
                    </div>
                    <PStyled className="lngSeparator">
                        |
                    </PStyled>
                    <div onClick={props.setLng.bind(this, 'es')}>
                        <p className={props.lng === 'es' ? 'lng lngSelected' : 'lng'}>ES</p>
                    </div>
                </div>
            </NavBarWrapper>
        </div>
    )
};

export default NavBar;