export const info = [
    {
        en: {
            address: "Monterrey, México.",
            bio: "I'm a systems administrator Engineer with experience in teams management and software development of Web Applications Programmer. Experience in development of Neural Networks. Enthusiast of learning everything that allows me to help in the construction of the future.",
            certificate: [
                {
                    date: "February 2020",
                    description: "Create websites from scratch with a dynamic and attractive graphical interface in the browser and mobile devices, applying HTML, CSS and Javascript.",
                    institution: "Platzi",
                    name: "React Development"
                },
                {
                    date: "December 2019",
                    description: "Data Science bootcamp using Python-based curriculum, real-world case studies, and machine learning concepts.",
                    institution: "Galvanize",
                    name: "Data Science Immersive"
                },
                {
                    date: "November 2019",
                    description: "Equivalent to level B2 of English proficiency.",
                    institution: "ETS",
                    name: "TOEFL iBT.  75 points."
                },
                {
                    date: "September 2018",
                    description: "Training course in basic skills and concepts to create applications for mobile devices. It presents the essential principles that should inspire the creation of applications, and thus focus its design and programming well from the beginning.",
                    institution: "Google Activate – Universidad Complutense de Madrid",
                    name: "Mobile Apps Development Course"
                },
                {
                    date: "June 2017",
                    description: "Learn the networking basics like switches, routers, and wireless products to help your business share applications, speed information access, and make the customer improve.",
                    institution: "Cisco",
                    name: "CCNA: Basic routing and switching principles"
                }
            ],
            education: [
                {
                    degree: "Bachelor’s degree of Systems administrator Engineer",
                    description: [
                        "Graduated with honorable mention. Average: 91.6.",
                        "Dissertation Thesis on Security System Based on S.T.R.I.D.E Using Artificial Intelligence.",
                        "Recognition for being part of the 'Group of 100' for obtaining one of the best 100 averages during the semester from August to December 2017 at the Engineering Faculty."
                    ],
                    endDate: "June 2019",
                    institution: "Universidad Autónoma de Nuevo León",
                    startDate: "August 2014"
                }
            ],
            experience: [
                {
                    company: "Globant",
                    endDate: "Present",
                    jobDescription: [
                        "Frontend Development of React Applications.",
                        "Development of General Apps to integrate the Brand of the Client.",
                        "Automatic test with Jest.",
                    ],
                    jobTitle: "Web UI Developer",
                    startDate: "July 2021"
                },
                {
                    company: "Neoris",
                    endDate: "July 2021",
                    jobDescription: [
                        "Development of Web Applications with JavaScript Technologies (React, NextJs, Angular, Ionic).",
                        "Frontend Development with React JS Framework & Backend Administration with Node.js Technologies.",
                        "Development of Progressive Web Apps.",
                        "Development of SharePoint Framework Solutions.", 
                        "Unit Testing & Integration Testing with Jest.",
                        "Training of Interns.",
                        "Development with scrum methodology.",
                        "Development team leader. (Experience leading a team of four developers and working with international teams)."
                    ],
                    jobTitle: "Software Development Analyst",
                    startDate: "October 2018"
                },
                {
                    company: "Universidad Autónoma de Nuevo León – Student Group",
                    endDate: "December 2018",
                    jobDescription: [
                        "The Faculty's Student Group has the purpose of being the students' representation towards the school.",
                        "Development, planning and coordination of activities with the purpose of complementing and stimulating student learning.",
                        "As president some of my activities included: Planning of Activities.",
                        "Coordination of working groups.",
                        "Task assignment.",
                        "Principal representation of the students.",
                        "During my period as president the quantity of students in the working group doubled, which allowed an increase in the activities developed by the student group both inside and outside the faculty."
                    ],
                    jobTitle: "Student President of the Systems Administrator Program",
                    startDate: "June 2017"
                },
                {
                    company: "TCA Software Solutions ",
                    endDate: "June 2018",
                    jobDescription: [
                        "Products Quality Analysis.",
                        "Elaboration of Acceptance Criteria.",
                        "Creation of Test Cases.",
                        "Functional and non-functional requirements Analysis.",
                        "Testing execution.",
                        "Manager of web interface projects.",
                        "Task assignment between me and my team of four more persons.",
                        "With the support of my team, productivity and speed in the software tests were improved to 300% in 6 months and the lowest percentage of errors was obtained once the product was delivered compared to other company projects."
                    ],
                    jobTitle: "Quality Analyst",
                    startDate: "May 2017"
                },
            ],
            name: "Juan Manuel Mendoza Maldonado",
            profession: "Frontend Developer",
            skills: [
                {
                    name: "HTML5",
                    percentage: "80%"
                },
                {
                    name: "CSS",
                    percentage: "90%"
                },
                {
                    name: "JavaScript",
                    percentage: "80%"
                },
                {
                    name: "React",
                    percentage: "70%"
                },
                {
                    name: "Analysis",
                    percentage: "75%"
                },
                {
                    name: "Communication",
                    percentage: "80%"
                },
                {
                    name: "Leadership",
                    percentage: "85%"
                },
                {
                    name: "English",
                    percentage: "75%"
                },
            ],
            social: [
                {
                    name: "instagram",
                    url: "https://www.instagram.com/_manuelmendoza"
                },
                {
                    name: "linkedin",
                    url: "https://www.linkedin.com/in/manuelmendozam"
                },
                {
                    name: "github",
                    url: "https://github.com/manuelmendozam"
                }
            ]
        },
        es: {
            address: "Monterrey, México.",
            bio: "Ingeniero administrador de sistemas con experiencia en manejo de grupos y desarrollo de software. Programador de Aplicaciones Web. Experiencia en el desarrollo de redes neuronales. Entusiasmado por aprender aquello que me permita contribuir a la construcción del futuro.",
            certificate: [
                {
                    date: "Febrero 2020",
                    description: "Crea sitios web desde cero con una interfaz gráfica dinámica y atractiva en el navegador y dispositivos móviles, aplicando HTML, CSS y Javascript.",
                    institution: "Platzi",
                    name: "Desarrollo con React"
                },
                {
                    date: "Diciembre 2019",
                    description: "Bootcamp de ciencia de datos que utiliza un plan de estudios basado en Python, estudios de casos del mundo real y conceptos de aprendizaje automático.",
                    institution: "Galvanize",
                    name: "Data Science Immersive"
                },
                {
                    date: "Noviembre 2019",
                    description: "Equivalente al nivel B2 de dominio del inglés.",
                    institution: "ETS",
                    name: "TOEFL iBT.  75 points."
                },
                {
                    date: "September 2018",
                    description: "Curso de formación en habilidades y conceptos básicos para la creación de aplicaciones para dispositivos móviles. Presenta los principios esenciales que deben inspirar la creación de aplicaciones, y así enfocar bien su diseño y programación desde el principio.",
                    institution: "Google Activate – Universidad Complutense de Madrid",
                    name: "Mobile Apps Development Course"
                },
                {
                    date: "June 2017",
                    description: "Enseña los conceptos básicos de redes, como conmutadores, enrutadores y productos inalámbricos para compartir aplicaciones, acelerar el acceso a la información y ayudar al cliente a mejorar.",
                    institution: "Cisco",
                    name: "CCNA: Basic routing and switching principles"
                }
            ],
            education: [
                {
                    degree: "Ingeniero Administrador de Sistemas",
                    description: [
                        "Graduado con Mención honorifica al obtener uno de los promedios más altos de la generación de 91.6.",
                        "Titulación con Tesis: “Security System Based on S.T.R.I.D.E Using Artificial Intelligence”.",
                        "Reconocimiento por formar parte del “Grupo de los 100” al obtener uno de los mejores 100 promedios de la facultad durante el semestre agosto a diciembre de 2017"
                    ],
                    endDate: "Junio 2019",
                    institution: "Universidad Autónoma de Nuevo León",
                    startDate: "Agosto 2014"
                }
            ],
            experience: [
                {
                    company: "Globant",
                    endDate: "Presente",
                    jobDescription: [
                        "Desarrollador Frontend con React JS",
                        "Desarrollo de Applicaciones Generales para integrar la marca del cliente.",
                        "Pruebas automatizadas con Jest."
                    ],
                    jobTitle: "Web UI Developer",
                    startDate: "Julio 2021"
                },
                {
                    company: "Neoris",
                    endDate: "Julio 2021",
                    jobDescription: [
                        "Desarrollo de Aplicaciones Web con tecnologías JavaScript.",
                        "Desarrollador Frontend con React JS & Administración de Backend en Node.js.",
                        "Desarrollo de Progressive Web Apps.",
                        "Desarrollo de SharePoint Framework Solutions.",
                        "Pruebas Unitarias y de Integración con Jest.",
                        "Capacitación de Practicantes.",
                        "Desarrollo bajo la metodología SCRUM.",
                        "Líder de equipo de desarrollo. (Grupo de 4 desarrolladores y trabajo con equipos internacionales)."
                    ],
                    jobTitle: "Software Development Analyst",
                    startDate: "Octubre 2018"
                },
                {
                    company: "Universidad Autónoma de Nuevo León – Student Group",
                    endDate: "Diciembre 2018",
                    jobDescription: [
                        "El Grupo Estudiantil de la Facultad tiene el propósito de ser la representación de los estudiantes. Desarrollo, planificación y coordinación de actividades con el fin de complementar y estimular el aprendizaje de los alumnos.",
                        "Planificación de Actividades.",
                        "Coordinación de Grupos de Trabajo.",
                        "Asignación de Tareas.",
                        "Principal representante de los estudiantes.",
                        "Durante mi periodo como presidente se duplico la cantidad de estudiantes en el grupo de trabajo, lo que permitió incrementar las actividades desarrolladas por el grupo estudiantil tanto dentro como fuera de la facultad."
                    ],
                    jobTitle: "Presidente Estudiantil del Programa Ingeniero Administrador de Sistemas",
                    startDate: "Junio 2017"
                },
                {
                    company: "TCA Software Solutions ",
                    endDate: "Junio 2018",
                    jobDescription: [
                        "Análisis de calidad de productos.",
                        "Elaboración de criterios de aceptación.",
                        "Creación de Casos de Prueba.",
                        "Análisis de requerimientos funcionales y no funcionales.",
                        "Ejecución de pruebas.",
                        "Administración de la división de proyectos de interfaces web.",
                        "Asignación de tareas entre mi equipo (4 personas) y yo. Con el apoyo de mi equipo, la productividad y la velocidad en las pruebas de software mejoraron a 300% en 6 meses y se obtuvo el porcentaje más bajo de errores una vez entregado el producto en comparación con las otras cedulas de la compañía."
                    ],
                    jobTitle: "Analista de Calidad",
                    startDate: "Mayo 2017"
                },
            ],
            name: "Juan Manuel Mendoza Maldonado",
            profession: "Frontend Developer",
            skills: [
                {
                    name: "HTML5",
                    percentage: "80%"
                },
                {
                    name: "CSS",
                    percentage: "90%"
                },
                {
                    name: "JavaScript",
                    percentage: "80%"
                },
                {
                    name: "React",
                    percentage: "70%"
                },
                {
                    name: "Analisis",
                    percentage: "75%"
                },
                {
                    name: "Communicación",
                    percentage: "80%"
                },
                {
                    name: "Liderazgo",
                    percentage: "85%"
                },
                {
                    name: "Inglés",
                    percentage: "75%"
                },
            ],
            social: [
                {
                    name: "instagram",
                    url: "https://www.instagram.com/_manuelmendoza"
                },
                {
                    name: "linkedin",
                    url: "https://www.linkedin.com/in/manuelmendozam"
                },
                {
                    name: "github",
                    url: "https://github.com/manuelmendozam"
                }
            ]
        },
    }
]
